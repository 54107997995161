/* Styles for ExchangeDetails */

.exchange-details-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 600px; 
    margin: 40px auto;
    
}

.exchange-details-main-text {
    font-weight: bolder;
    font-size: 1.2rem;
    color: #0056b3;
    background-color: #e7f1ff;
    padding: 10px;
    border-left: 5px solid #0056b3;
    margin: 10px 0;
    border-radius: 5px;
    white-space: pre-wrap;
}

.exchange-details-main-text b {
    color: black;
    font-weight: 900;
}
.exchange-details-main-block {
    position: absolute;
    width: 90%;
}
.exchange-details-text {
    font-size: 16px;
    margin-bottom: 15px;
    text-align: left;
    white-space: pre-wrap;
}

.exchange-details a {
    color: black;
}

/* Общие стили для заголовков разделов */
@font-face {
    font-family: 'Cera Round Pro';
    src: url('../../fonts/CeraRoundPRO/TypeMates - CeraRoundPro-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: #ffffff;
    font-family: 'Cera Round Pro', sans-serif;
    font-size: var(--font-size-root);
    color: rgb(var(--rgb-gray-900));
    line-height: var(--line-height-copy);
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

:is(h1, h2, h3, .h3, .h1, .h2,) {
    color: #53405d;
}

:is(p) {
    font-size: 20px;
    margin-top: 1rem;
    margin-bottom: 1rem;    

}

.rules-container {
    margin-top: 2%;
    display: flex;
    background-color: #ffffff;
    align-items: stretch;
    justify-content: flex-start;
    border-bottom: 2px solid rgba(31, 31, 57, 0.1);
    padding-bottom: 20px !important;
}

.rules-container h2 {
    font-size: 24px;
}
.rules-container h3 {
    font-size: 20px;
}
.rules-container h4 {
    font-size: 18px;
}
.rules-wrapper {
    padding: 0 5% 0 5%;
    min-width: 80%;
}


.section-title-container span {
    color: #8F77F0;
    font-size: 24px;
}

.title-icon, .section-title-icon {
    margin-right: 10px;
    color: #fff;
    font-size: 24px;
}

h1, .section-title {
    font-weight: 600;
    margin: 0;
    font-size: 24px;
}

/* Общие стили для контейнеров с содержимым */
.content-container {
    padding: 20px;
    border-radius: 10px;
    margin: 50px auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    white-space: pre-wrap;
}

.content-container p,.content-container li  {
    font-family: 'Geologica', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: #969499;
    width: auto;
    margin: 0;
}

.content-container li {
    margin-left: 2rem;
}

.button-container {
    display: flex; /* Включаем Flexbox */
    flex-direction: row;
    text-align: center;
    justify-content: space-between; /* Центрируем содержимое по горизонтали */
    width: 100%; /* Задаем полную ширину */
    margin-top: 20px; /* Добавляем отступ сверху */
    gap: 20px;
}



.contact-button {
    background-color: #fff;
    color: rgba(150, 148, 153, 1);
    border-radius: 8px; /* Скругление углов кнопки */
    padding: 10px 20px;
    font-size: 1rem; /* Размер шрифта кнопки */
    margin-top: 20px; /* Отступ сверху для кнопки */
    border: 2px solid rgba(150, 148, 153, 1);
    border-bottom: 4px solid rgba(150, 148, 153, 1);
    font-weight: 600;
    cursor: pointer; /* чтобы показать, что это кликабельный элемент */
    transition: background 0.3s ease; /* плавный переход фона */
    text-align: center;
}

.contact-button:hover {
    background-color: rgba(157, 251, 254, 1);
    color: #000;
    text-decoration: none;
}

.contact-button:disabled {
    background-color: #fff;
    color: rgba(150, 148, 153, 1);
    font-size: 1rem;
    border: 2px solid rgba(150, 148, 153, 1);
    border-bottom: 4px solid rgba(150, 148, 153, 1);
    cursor: not-allowed;
}

.input-error {
    margin-bottom: 0.5rem;
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

.success-popup, .error-popup {
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
    margin-bottom: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* добавляем тень */
    display: flex; /* чтобы иконка и текст были в одной строке */
    align-items: center; /* вертикальное выравнивание иконки и текста */
    animation-duration: 0.5s;
    
}

.show {
    animation-name: fadeIn;
}

.hide {
    animation-name: fadeOut;
}

.success-popup {
    background-color: rgba(5, 109, 5, 0.7);
}

.error-popup {
    background-color: rgba(148, 4, 4, 0.7);
}

.success-popup:before, .error-popup:before {
    font-family: "Font Awesome 5 Free"; /* пример с Font Awesome иконками */
    font-weight: 900;
    margin-right: 10px; /* отступ между иконкой и текстом */
}

.success-popup:before {
    content: "\f00c"; /* галочка */
}

.error-popup:before {
    content: "\f00d"; /* крестик */
}

/* ... Ваши текущие стили ... */
.content-with-left-sidebar {
    background-image: url('./../img/leftMainSideImg.svg');
    background-position: left top;
    margin-right: 1%;
}

.content-with-right-sidebar {
    background-image: url('./../img/rightMainSideImg.svg');
    background-position: right top;
    margin-left: 1%;
}


.content-with-left-sidebar, .content-with-right-sidebar {
    position: relative;
    overflow: hidden;
    background-repeat: repeat-y;
    background-size: contain;
    width: 9.3%;
    min-height: 0;
}
.exchange-container {
    flex-direction: column;
    display: flex;
    justify-content: flex-start; /* изменено с center на flex-start */
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    max-width: none; /* Удаляем ограничение по ширине */
    width: 100%;
    margin: 20px auto;
}

.container-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    background-color: #000000; /* Применяем цвет, который вы использовали для ссылок */
    color: #ffffff; 
    border: none; 
    padding: 10px 20px; 
    border-radius: 8px 8px 0 0; 
    transition: background-color 0.3s, transform 0.2s;
    outline: none;
    text-decoration: none; 
    font-size: 24px;
    width: 100%;
}
.container-name h1 {
    color: #ffffff; 
    text-decoration: none; 
    font-size: 24px;
}
.form-container-name {
    background-color: #8F77F0;
    font-size: 24px;
}

.black-bg {
    background: #000000;
}
.flex-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    border: 2px solid #000000; /* пример границы */
    border-bottom: 8px solid #000000;
    border-radius: 0 0 10px 10px; /* скругление углов границы */
    padding: 20px; /* добавление внутреннего отступа */
    box-sizing: border-box;

}

.flex-form-container {
    border: 2px solid #8F77F0; /* пример границы */
    border-bottom: 8px solid #8F77F0;
}

.font-regular-20,
.font-regular-24 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #8F77F0;
}

.font-regular-16 {
    font-size: 20px;
}
.font-black {
    color: #000000;
}

.mw-50 {
    min-width: 30% !important;
    max-width: 60% !important;
    text-align: center;
}
.font-gr {
    color: #58555D;
}
@media (max-width: 1024px) {

    body {
        font-size: 16px; /* уменьшим базовый размер шрифта */
    }
    .rules-container {
        display: contents;
    }

    .section-title-container {
        padding: 8px 8px;
        margin: 15px auto;
    }

    .title-icon, .section-title-icon {
        font-size: 20px; /* уменьшим размер иконки */
    }

    h1, h3, .section-title {
        font-size: 1.5rem !important; /* уменьшим размер заголовка */
    }
    h2 {
        font-size: 18px;
    }
    p {
        font-size: 16px;
    }

    .content-container {
        margin: 20px 10px; /* уменьшим внешний отступ */
        padding: 15px; /* уменьшим внутренний отступ */
    }

    .contact-button {
        padding: 8px 12px; /* уменьшим внутренние отступы кнопки */
    }

    .success-popup, .error-popup {
        padding: 15px; /* уменьшим внутренние отступы попапов */
    }
    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center; /* Центрирование по горизонтали */
        gap: 16px; /* Добавляем отступы между кнопками */
      }

    .rules-wrapper {
        max-width: none;
    }
    .mw-50 {
        max-width: 100% !important;
    }
}

.fixied-tg-btn {
    position: fixed;
    display: flex;
    gap: 8px;
    bottom: 0;
    left: 2%;
    padding: 8px 24px;
    background: #8F77F0;
    border-radius: 12px 12px 0px 0px;
    color: #ffffff;
    z-index: 10;
    color: #ffffff;
    text-decoration: none !important;
}
.fixied-tg-btn:hover {
    background: #6AE4FB;
}

@media (max-width: 768px) {
    .left-sidebar-background-img,
    .right-sidebar-background-img {
          display: none;
      }
    .font-regular-16 {
        font-size: 16px;
    }
    .fixied-tg-btn {
        left: 30%;
        bottom: 0;
        transform: translateX(-50%);
        padding: 12px 28px;
    }
}

.pre-wrap-text {
    white-space: pre-wrap;
}


.centered-form > .exchange-form {
    margin: 0 auto;
}

.centered-form > .exchange-info,
.centered-form > .error-message {
    display: none;
}

.exchange-form {
    flex: 1;
    padding: 20px;
}


.exchange-form {
    padding-right: 20px; /* отступ справа для формы */

}

.exchange-info {
    font-weight: bolder;
}

.exchange-form-title {
    color: #333;
    font-size: 18px;
    margin-bottom: 10px;
}

.exchange-item {
    margin-bottom: 30px;
    position: relative;
}

.exchange-input-label {
    position: absolute;
    left: 10px;
    transition: font-size 0.3s, top 0.3s;
    pointer-events: none;
    color: #888;
    top: 65%;
    transform: translateY(-50%);
}

.exchange-input,
.user-data-input {
    display: block;
    padding: 10px;
    border: 1px solid #8b7575;
    background-color: transparent;
    height: 40px;
    align-items: center;
    margin-bottom: 10px;
    transition: opacity 0.1s;
}

.exchange-input {
    width: 100%;
    border-radius: 20px;
}

.exchange-input-error {
    border: 1px solid red; /* Состояние ошибки */
}

.user-data-input {
    width: 100%;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.exchange-input::placeholder {
    color: #888;
    opacity: 1;
}

.exchange-input:focus + .exchange-input-label,
.exchange-input:not(:placeholder-shown) + .exchange-input-label {
    top: -2px;
    font-size: 13px;
}

.rate-info, 
.error-message {
    align-items: center;
    border-radius: 5px;
}

.rate-info {
    color: #45893a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

.parent-container {
    display: flex;
    flex-wrap: wrap;
}

.exchange-form {
    min-width: 40%; /* или другое значение, которое вы считаете подходящим */
}

.error-message {
    background-color: #ffbaba;
    color: #d8000c;
    padding: 10px;
}

.transfer-text {
    color: #333;
    font-size: 13px;
    flex-shrink: 0;
    white-space: nowrap; 
}

.input-group p {
    margin: 0;
    margin-bottom: 5px;
}
.exchange-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;

    flex-direction: column;
    gap: 5px;
}

.exchange-form h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 2rem;
    color: black;
}

.user-data-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
   
}

.currency-label {
    display: flex;
    font-size: 12px;
    order: 2;
}

.exchange-input:hover, 
.user-data-input:hover,
.exchange-input:focus, 
.user-data-input:focus {
    opacity: 0.6;
    outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
}

.input-group {
    width: 100%;
    display: flex;
    justify-content: center;
}


input[type="checkbox"] {
    display: none;
}

.custom-checkbox {
    justify-content: flex-start !important;
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid #8b7575;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
}

.custom-checkbox::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.3s ease;
}

input[type="checkbox"]:checked + .custom-checkbox::before {
    transform: translate(-50%, -50%) scale(1);
}

input[type="checkbox"]:checked + .custom-checkbox::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M1.5 6L5 9L10.5 1.5" fill="none" stroke="black" stroke-width="2"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(-50%, -50%);
}

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}


.custom-label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}
.error-form-message, .info-msg {
    
    width: 100%; /* Ширина сообщения об ошибке равна ширине input-group */
    color: red;
    font-size: 0.75rem;
    text-align: center; /* Если нужно выровнять текст по центру */
    display: flex;
}

.info-msg {
    padding: 0.1rem;
    border: 1px solid #B4D5FF;
    color: #00529B;
    background-color: #BDE5F8; /* Светлый фон для информационных сообщений */
    margin-top: -1%;
    margin-bottom: 1rem;
}

@media (max-width: 1024px) {
    .flex-container {
        flex-direction: column;
    }
    .exchange-form-wrapper {
        flex-direction: column;
        align-items: stretch; /* выровнять элементы по ширине контейнера */
        padding: 0 20px; /* добавить немного отступа по краям */
    }

    .exchange-form,
    .rate-info,
    .exchange-info {
        flex: 1 1 100%; /* позволяет элементам занимать всю ширину контейнера */

        
    }

    .exchange-form-title {
        font-size: 16px; /* уменьшить размер шрифта заголовка */
    }

    .exchange-item {
        margin-bottom: 20px; /* уменьшить вертикальный отступ между элементами формы */
    }

    .exchange-input,
    .user-data-input {
        height: 35px; /* уменьшить высоту полей ввода */
        font-size: 12px;
    }
    .exchange-input-label,
    .currency-label,
    .transfer-text {
        font-size: 12px; /* уменьшить размер шрифта меток */
    }

    .exchange-input-container,
    .user-data-input-container {
        flex-direction: column; /* переключиться на вертикальное выравнивание */
        align-items: center; /* элементы растягиваются на всю ширину */
    }


    .rate-info {
        gap: 5px;
        justify-content: center;
        align-items: start;
    }
    .exchange-input, .exchange-input::placeholder {
        font-size: 10px; /* Меньший размер шрифта для мобильных устройств */
    }
    .css-1i49qam-placeholder {
        font-size: 13px;
    }
}


.input-container {
    position: relative;
    width: 100%;
  }
  
  .input-container label {
    position: absolute;
    top: 10px; /* Зафиксированное положение */
    left: 10px;
    background-color: white;
    padding: 0 5px;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
    font-size: 12px; /* Уменьшенный размер текста для фиксированного положения */
    color: #8F77F0;
  }
  
  .select-wrapper {
    position: relative;
    width: 100%;
  }
  
  .css-2b097c-container {
    width: 100%;
  }
  
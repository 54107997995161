ul {
    margin: 0px;
    padding: 0px;
}
.footer-section {
  background: rgba(46, 42, 52, 1);
  position: relative; /* Изменено на fixed */
}

.single-cta i {
  color: #00C6CF;
  font-size: 30px;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
  margin-bottom: 20px;
}

.single-cta i, .cta-text {
  vertical-align: top;
}
.single-cta {
  display: flex;
  align-items: flex-start;
}
.cta-text h4, .cta-text h3  {
  color: #00C6CF;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span, .cta-text address {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
    max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
      color: #7e7e7e;
  line-height: 28px;
}

.footer-widget-heading h3 {

  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.copyright-area{
  background: #202020;
  padding: 20px ;
}
.copyright-text p {
  font-size: 14px;
  color: #878787;
  margin-left: 20px;

}
.copyright-text p a{
  color: #ff5e14;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: rgba(255, 255, 255, 0.1);
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.footer-cta {
  border-bottom: 1px solid #00C6CF;

}

.social-items-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.social-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 20px; /* Отступ между элементами */
  margin-bottom: 20px; /* Отступ снизу */
  width: 100%;
}

.social-items-wrapper h3 {
  font-size: 32px;
  color: #8F77F0;
  
}
/* Общие стили для кнопок */
.social-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 12px;
  color: #060507; /* Цвет текста */
  background: #8F77F0; /* Фоновый цвет кнопок */
  gap: 16px;
}

.social-button-small {
  padding: 0.5rem;
}
/* Стили для иконок */
/* Стили для текста внутри кнопок */
.social-button:hover{
  text-decoration: none;
  color: #060507;
  background: #9DFBFE

}

.social-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  gap: 16px;

}

.social-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fcfcfc;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid rgba(202, 201, 204, 1);
  gap: 16px;
}

.social-item a {
  color: rgba(202, 201, 204, 1);
}

.social-item img {
  margin-right: 16px;
}

.social-item:hover {
  text-decoration: none;
  color: #060507;
  background: #9DFBFE;
}


@media (max-width: 768px) {
  /* Уменьшение отступов и размеров текста */
  .footer-cta, .footer-content, .footer-pattern img, .footer-logo, .footer-text p, .footer-social-icon span, .footer-widget-heading h3 {
    padding: 0 15px; /* Добавьте отступы по бокам */
  }
  /* Управление выравниванием и направлением flex-контейнера */
  .social-items-container {
    flex-direction: column;
    align-items: center; /* Выравниваем элементы по центру */
  }
  .social-items {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .social-button {
    width: 100%;
  }
  .rigte-align {
    flex-direction: column;
  }
  /* Переопределение стилей для кнопок */
  .social-button, .social-item {
    width: 100%; /* Кнопки теперь займут всю ширину контейнера */
    justify-content: center; /* Текст и иконки будут по центру */
    margin-bottom: 10px; /* Добавляем нижний отступ между кнопками */
  }

  /* Убедитесь, что ссылки и текст также центрируются */
  .social-item a {
    justify-content: center; /* Центрирование содержимого для адаптивности */
  }

  /* Адаптация линии разделения */

  /* Выравнивание меню в футере */
  .footer-menu {
    text-align: center; /* Центрируем меню */
  }
  .footer-menu li {
    display: block; /* Каждый пункт меню на новой строке */
    margin: 10px 0; /* Добавляем верхний и нижний отступы */
  }

  /* Адаптация контента копирайта */
  .copyright-area {
    text-align: center; /* Центрирование текста */
  }
  .pb-5 {
    padding: 1rem !important
  }
}


.partners-wrapper {
    margin-top: 2%;
    display: flex;
    background-color: #ffffff;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    margin-bottom: 2%;
}

.partners-title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
.partners-title-container p {
  width: 60%;
  color: #58555D;
}
.partners-title-text {
    font-size: 24px;
    color: #333;
    font-weight: bold;
    margin: 0;
    padding: 10px 0;
  }
.partners-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  
.partners-container div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
  
.partners-container img {
    display: block;
    width: 100px; /* Установите фиксированный размер для всех изображений */
    height: auto;
    transition: transform 0.3s ease;
  }
  
.partners-container img:hover {
    transform: scale(1.1); /* Эффект увеличения при наведении */
  }


@media (max-width: 1024px) {
  .partners-title-container p {
    width: 100%;
  }
}
.advantage-wrapper {
    display: flex;
    background-color: #ffffff;
    align-items: stretch;
    justify-content: center;
    border-bottom: 2px solid rgba(31, 31, 57, 0.1);
    padding-bottom: 20px !important; 
}

.advantages-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    background-color: #F2F2F2;
    border-radius: 24px;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
}


.b-white {
    background-color: #ffffff
}
.b-grey {
    background-color: #F2F2F2
}
.advantages-container__row {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.adv-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.advantages-container__row > * {
    flex: 1 1 calc(50% - 32px); /* по половине ширины с учетом gap */
  }

.adv-content-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    gap: 10px;
    border: 2px solid #58555D;
    border-radius: 24px;
    margin-bottom: 20px;
}


.advantages-container .content-block::before {
    all: unset;
}

.advantage-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem auto;
    gap: 2rem;
}
.advantage-title {
    max-width: 60%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 2%;
}

.advantage-content-wrapper {
    width: 90%;
    font-size: 24px;
    margin: 0 auto;
    margin-bottom: 2%;
    padding: 32px;
}

.advantage-content-wrapper b {
    color: rgba(143, 119, 240, 1);
}

.left-sidebar-background-img {
    background-image: url('./../../../img/advantage-icon-left.svg');
    background-position: left top;
    margin-left: 1.5%;
}

.right-sidebar-background-img {
    background-image: url('./../../../img/advantage-icon-right.svg');
    background-position: right top;
    margin-right: 1.5%;
}

.left-sidebar-background-img, .right-sidebar-background-img {
    position: relative;
    overflow: hidden;
    background-repeat: repeat-y;
    background-size: contain;
    width: 25%;
    min-height: 0;
}

.advantage-block img {
    max-width: 70%; /* увеличено значение, чтобы изображение было больше на мобильных устройствах */
    height: 220px;
    object-fit: contain;
    margin-bottom: 20px; /* добавлен нижний отступ для изображения */
}

.advantage-block p {
    flex: 1;
    width: 100%; /* чтобы текст занимал всю ширину блока */
}

.advantage-block {
    box-sizing: border-box; /* Adjust as necessary for your layout */
    border: 3px solid rgba(150, 148, 153, 1);
    box-shadow: 0px 5px 0px rgba(150, 148, 153, 1);
    border-radius: 24px;
    margin-bottom: 32px; /* Gap between items */
    overflow: hidden; /* Ensures the border-radius is applied on children as well */
}


.advantage-block {
    background-color: rgba(237, 239, 240, 1);
    display: flex;
    flex-direction: column; /* изменено на column для улучшения адаптивности */
    align-items: center; /* выровнять элементы по центру */
    padding: 20px;
    min-height: 200px;
    text-align: center; /* текст выровнен по центру */
}

.bot-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    justify-items: center;
    gap: 4rem;
    padding: 1rem;
    width: 80%;
}

.bot-img {
    width: 18.75rem;
    height: auto
}

.bot-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
}

.bot-info-container {
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.bot-info-container li {
    font-style: normal;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #58555D;
    list-style-type: none;
    position: relative;
    padding-left: 40px;
}


.bot-info-container li::before {
    content: "✓";
    color: #fff;
    font-size: 1rem;
    position: absolute;
    left: 0;
    top: 0.5rem;
    background-color: #8F77F0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 40%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-container .bot-info-btn {
    width: 100%;
    margin-left: 0;
    background: #EDEFF0;
}


.bot-info-transfer {
    max-width: 90%;
    display: grid;
    grid-gap: 1.3rem;
    grid-template-columns: repeat(3, 1fr);
}

.bot-info-transfer-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0 4rem;
    gap: 1rem;
}

.bot-info-number-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.bot-info-number {
    color: #8F77F0;
    font-weight: 700;
    font-size: 64px;
    line-height: 80px;
    position: relative;
}

.bot-info-number-wrapper::before {
    content: ""; /* Необходимо для отображения псевдоэлемента */
    position: absolute;
    background-color: #8F77F0;
    width: 100%; /* Черточка теперь на всю ширину контейнера */
    height: 5px;
    left: 0;
    bottom: 0; /* Позиционирование черточки ниже текста */
    display: block; /* flex не нужен, если элемент - простая линия */
}

.pink-b b {
    color: #8F77F0;
}

.p-16-16 {
    padding: 1rem 0rem 1rem 0.5rem;
}

.p-0 {
    padding: 0;
}


.adv-content{
    font-family: 'Geologica';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #58555D;
    flex: none;
    order: 0;
    align-self: stretch;
}

@media (max-width: 1024px) {
    .bot-info-transfer {
        grid-template-columns: repeat(2, 1fr);
    }

}
@media (max-width: 768px) {
    .bot-content {
        flex-direction: column;
    }
    .bot-info-transfer {
        max-width: 100%;
        grid-template-columns: repeat(1, 1fr);

    }
    .advantages-container {
        max-width: 100%;
        margin: 0;
        padding: 10px;
    }
    .advantages-container__row {
        flex-direction: column;
    }
    .advantage-content-wrapper {
        font-size: 14px;
        line-height: 17.5px;
        padding: 10px 0;
        align-items: center;
        text-align: center;
    }
    .advantage-block {
        flex-direction: row;
        padding: 0;
        text-align: left;
        font-size: 12px;
        min-height: 100px;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
    .advantage-block img {
        width: 30%;
        height: auto;
        margin-bottom: 0;
    }
}


/* contacts.css */
.contacts-wrapper {
    margin-top: 2%;
    display: flex;
    background-color: #ffffff;
    align-items: stretch;
    justify-content: flex-start;
    border-bottom: 2px solid rgba(31, 31, 57, 0.1);
    padding-bottom: 20px !important;
}

.contact-section {
    flex: none; /* заменяем flex: 1; */
    width: calc(50% - 40px); /* ширина равна половине экрана минус отступы */
    margin: 5% 1%;
    box-sizing: border-box;
}
/* Сделайте раздел с формой чуть меньше по ширине */
.contact-section.form-section {
    width: calc(30% - 40px); /* меняем flex: 0.4 на фиксированную ширину, если нужно */
}


.contact-info {
    width: 45%;
}

.contact-form {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    flex-direction: column; 
}


.contact-info-title, .contact-form-title {
    color: #333;
    font-size: 18px;
    margin-bottom: 10px;
}

.input-label {
    position: absolute;
    left: 10px;
    transition: font-size 0.3s, top 0.3s;
    pointer-events: none;
    color: #888;
    top: 65%;
    transform: translateY(-50%);
}

.contact-item {
    margin-bottom: 30px;

}

.contact-item h2 {
    font-size: 1.25rem;
    color: #000;
}
.contact-icon {
    margin-right: 10px;
}

.input-container {
    position: relative;
    padding-top: 20px;
    margin-top: 20px;
}

.contact-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #8b7575;
    background-color: transparent;
    height: 40px; /* Задаем фиксированную высоту */
    padding: 10px 10px 10px; 
}

.contact-input[type="textarea"], #message {
    height: 100px;
    
}

.contact-input::placeholder {
    color: #888;
}

.contact-input:focus + .input-label,
.contact-input:not(:placeholder-shown) + .input-label {
    top: -2px;
    font-size: 13px;
}

.contact-input[type="textarea"] {
    flex-grow: 1;
    padding: 25px 10px; /* Увеличиваем верхний padding для textarea */
    min-height: 150px; /* Увеличиваем высоту textarea */
}

.contact-input::placeholder {
    opacity: 0;
}

.address-item {
    margin-bottom: 10px;
    margin-top: 10px;
}

.contact-info {
    flex: 1; /* Делаем так, чтобы элемент растягивался и занимал все доступное пространство */
    max-width: none; /* Удаляем ограничение по ширине */
}

.contact-info-container {
    display: flex;
    flex-direction: row; 
    align-items: center;
}

.info {
    padding-right: 0;
    flex-grow: 1; /* Занимает все оставшееся место */
}

.map {
    width: 100px; /* Ширина изображения карты */
    height: 100px;
    flex: none; /* Растягиваем контейнер, чтобы он занимал всё доступное пространство в родительском контейнере */
    border: 1px solid #8b7575;
    position: relative; 
}

.map img {
    position: absolute; /* Абсолютное позиционирование изображения */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contact-info a {
    color: #5296f5; /* Измените цвет ссылок на ваш желаемый */
    text-decoration: none;
    transition: color 0.3s; /* Добавляем плавное изменение цвета при наведении */
  }
  
.contact-info a:hover {
    text-decoration: underline;
  }

/* ... Ваши текущие стили ... */

@media (max-width: 1024px) {

    .contacts-wrapper {
        flex-direction: column; /* переключаем на вертикальное расположение */
        justify-content: center;
        align-items: center;
    }

    .contact-section {
        width: 90%; /* делаем блок на всю ширину экрана */

    }

    .contact-section.form-section {
        width: 90%; /* делаем блок на всю ширину экрана */
    }

    .contact-container {
        flex-direction: column; /* вертикальное расположение элементов */
    }

    .contact-info, .contact-form {
        width: 100%; /* делаем блоки на всю ширину экрана */
    }

    .contact-input[type="textarea"], #message {
        min-height: 120px; /* немного уменьшаем высоту textarea */
    }

    .contact-info-container {
        flex-direction: column; /* вертикальное расположение элементов */
        align-items: flex-start; /* выравнивание по левому краю */
    }

    .map {
        margin-top: 20px; /* добавляем верхний отступ для карты */
        width: 100%; /* делаем изображение карты на всю ширину экрана */
        padding-bottom: 25%; /* высота равна 25% от ширины блока, чтобы получить прямоугольник */
        position: relative;
    }

    .map img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

}

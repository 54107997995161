.auth-title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;

}

.auth-link-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
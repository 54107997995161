.filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
  
.custom-date-filter {
    display: flex;
    gap: 10px;
}
  
.custom-date-filter input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
  
.filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .filter__input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .custom-date-filter {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
  }
  
  .custom-date-filter input[type='date'] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .custom-date-filter .apply-button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-date-filter .apply-button:hover {
    background-color: #45a049;
  }

  .users-title-period {
    font-size: 14px;
    color: #A2A2A2;
    margin-top: 10px;
}
  
  @media (max-width: 720px) {
    .custom-date-filter {
        flex-direction: column;
    }
  }
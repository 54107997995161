/* Основные стили для navbar */

.navbar {
    margin: 2rem 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    padding: 0;
    z-index: 10;
}

/* Стили для пунктов меню */
.navbar a {
    font-family: 'Geologica';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #969499; /* цвет текста из Figma */
    text-decoration: none;
    padding: 8px 16px; /* padding из Figma для пунктов меню */
    transition: background 0.3s, color 0.3s;
}

/* Эффект наведения для пунктов меню */
.navbar a:hover {
    color: #000000; /* Черный цвет при наведении */
    opacity: 1; /* Удалите прозрачность при наведении */
}

/* Стили для неактивных ссылок */
.navbar a:not(.active-link) {
    font-weight: 250; /* менее жирный текст для неактивных ссылок */
    opacity: 1;
}

/* Активная ссылка */
.navbar-items .active {

    color: #000000 !important;
}

/* Стили для логотипа (если он есть) */
.navbar-logo {
    width: 138px; /* ширина логотипа из Figma */
    height: 35.4px; /* высота логотипа из Figma */
    position: relative;
    overflow: hidden;
}

.navbar-logo img {
    max-width: 100%;
    max-height: 100%;
}

/* Элементы меню в navbar */
.navbar-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navbar-items li {
    width: auto;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-items ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.menu-toggle {
    display: none; /* по умолчанию скрываем кнопку */
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    position: absolute; /* абсолютное позиционирование */
    top: 20px; /* отступ сверху */
    right: 10%; /* отступ справа */
    cursor: pointer;
}

.bar {
    background-color: #00C6CF; /* цвет полосок */
    height: 3px;
    width: 100%;
    border-radius: 2px;
}

.close-menu {
    display: none; /* по умолчанию скрыта */
}

.auth-container {
    padding: 4px 24px;
    border: 1px solid #969499;
    border-radius: 12px;
}

@media (max-width: 1024px) {
    .navbar {
        margin-left: 20px;
        margin-right: 20px;
        justify-content: space-between; /* Логотип слева, кнопка меню справа */
    }

    .menu-toggle {
        display: flex; /* Показываем кнопку меню на мобильных устройствах */
        /* Стили кнопки меню, если нужно */
    }

    .navbar-items {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        z-index: 1000;
    }

    .navbar-items.active {
        transform: translateX(0); /* Показать меню */
    }

    .navbar-items ul {
        display: flex;
        flex-direction: column; /* Вертикальное расположение */
        align-items: center;
        width: 100%;
        padding: 0;
    }

    .navbar-items li {
        width: 100%; /* Полная ширина для каждого пункта меню */
    }

    .navbar-items a {
        text-align: center;
        display: block;
        width: 100%;
    }

    .close-menu {
        display: flex;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        /* Дополнительные стили для крестика */
    }
}

.language-switcher {
    position: relative;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-btn {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .arrow {
    margin-left: 5px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: -40%;
    border: 1px solid #ccc;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 150px;
    padding: 5px 0;
    list-style: none;
    display: flex;

    flex-direction: column;
  }
  
  .dropdown-menu li {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 8px;
  }
  
  .dropdown-menu li:hover {
    background-color: rgba(157, 251, 254, 1);
    color: #000;
  }
  
  .flag-icon {
    margin-right: 8px;
    width: 24px;
    height: 16px;
  }
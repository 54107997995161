.office-info-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
.office-info-container {
    display: flex;
    padding: 32px;
    border: 2px solid #2E2A34;
    box-shadow: 0px 5px 0px #2E2A34;
    border-radius: 24px;
  }

  
.office-title {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.office-details {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }


.office-details-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  border: 2px solid #2E2A34;
  box-shadow: 0px 5px 0px #2E2A34;
  gap: 20px;
  padding: 32px;
  border-radius: 24px;
}
  
  
.office-address, .office-services, .office-contacts, .office-hours {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
  }

.of-info {
    font-family: 'Geologica';
    font-size: 18px;
    line-height: 22px;
    color: #8F77F0;
  }
  
  @media (max-width: 768px) {
    .office-details-wrapper {
      flex-direction: column;
    }
}

.star-rating-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Выравниваем звезды по левому краю */
}

/* Стили для звезд */
.star {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px; /* небольшой отступ между звездами */
    font-size: 1.5rem; /* размер звезды */
    color: #ccc; /* цвет неактивной звезды */
    transition: color 0.3s; /* плавное изменение цвета */
    outline: none; /* убираем контур у кнопки */
}

.star.active {
    color: #FFD700;
}


.review-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.user-icon {
    font-size: 40px;
    margin-right: 15px;
}
.review-content {
    position: relative;
    padding-top: 30px; 

    border-radius: 5px;
    box-shadow: 0 0 6px #B2B2B2;
    display: inline-block;
    padding: 10px 18px;
    position: relative;
    vertical-align: top;
    margin: 10px 10px;
    border-color: #cdecb0;
    width: 80%;
}

.review-name {
    color: #8F77F0;
    font-weight: bold;
    margin-bottom: 8%;
    flex-grow: 1; /* Позволяем имени растягиваться */
    overflow: hidden; /* Обрезаем длинное имя */
    white-space: nowrap; /* Предотвращаем перенос на новую строку */
    text-overflow: ellipsis; /* Добавляем многоточие в конце, если имя слишком длинное */

}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.readonly-stars .star {
    font-size: 1rem;
}

.review-message {
    margin-top: 5px;
}

    /* Уголок сообщения */
.review-content::before {
    background-color: #fefefe;
    content: "\00a0";
    display: block;
    height: 16px;
    position: absolute;
    top: 11px;
    transform:             rotate( 29deg ) skew( -35deg );
        -moz-transform:    rotate( 29deg ) skew( -35deg );
        -ms-transform:     rotate( 29deg ) skew( -35deg );
        -o-transform:      rotate( 29deg ) skew( -35deg );
        -webkit-transform: rotate( 29deg ) skew( -35deg );
    width:  20px;
    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );
    left: -9px; 
}
  
.time {
    display: block; 
    text-align: left; /* Выравниваем текст по центру */
    color: #000;
    font-weight: 600;
    font-size: 0.9rem; /* Опционально: уменьшаем размер шрифта */
    margin-bottom: 5px; /* Добавляем небольшой отступ перед именем */
}
  
/* ... Пагинация ... */

.pagination-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.pagination-buttons button {
    background-color: #4CAF50; /* или любой другой цвет на ваше усмотрение */
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 8px;
    transition-duration: 0.4s;
    cursor: pointer;
}
  
.pagination-buttons button:hover {
    background-color: #45a049; /* цвет при наведении */
}
  
.pagination-buttons button:disabled {
    background-color: #ddd;
    color: #666;
    cursor: not-allowed;
}
  

@media (max-width: 1024px) {

    .star-rating-container {
        margin-top: 30px; /* Увеличиваем верхний отступ для рейтинга */
    }

    .star {
        margin-right: 12px; /* увеличиваем отступ между звездами для лучшего касания */
        font-size: 2rem; /* увеличиваем размер звезд для удобства касания на мобильных устройствах */
    }
}

  
.main-dash-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2% 5%;
}

.dashboard-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
  gap: 3rem;
}

.left-dash-container,
.bar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.875rem 1.875rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(30, 119, 182, 0.08);
  border: 2px solid #000000;
  border-radius: 10px;
  border-bottom: 8px solid #000000;
  min-height: 300px;
}


.bar-container ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bar-container button {
  all: unset;
}

.bar-container ul {
  list-style: none;
}

.bar-container li a,
.bar-container button {
  display: flex;
  gap: 0.5rem;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #969499;
  outline: none;
  text-decoration: none; 
  cursor: pointer;
  text-wrap:nowrap
}

.bar-container li a:hover,
.bar-container li a.active,
.bar-container button:hover {
  color: #8F77F0;
}

.left-dash-container {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.input-label {
  position: absolute;
  left: 10px;
  transition: font-size 0.3s, top 0.3s;
  pointer-events: none;
  color: #888;
  top: 65%;
  transform: translateY(-50%);
  color: rgba(21, 60, 91, 0.57);
}

.form-container {
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.dash-input-container {
  display: flex;
  position: relative;
  padding-top: 20px;
  overflow: hidden; /* Для обрезки псевдоэлемента */
}

.form-input,.form-textarea {
  width: 20rem !important;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  padding: 10px 10px 10px;
  border: 1px solid #8b7575;
  background: transparent;
  position: relative; /* Необходимо для псевдоэлемента */
}

.form-textarea {
  width: 60rem!important;
  height: 20rem;
}

.form-input:focus + .input-label,
.form-input:not(:placeholder-shown) + .input-label {
  top: 20px; /* Измените это значение по вашему усмотрению */
  left: -1px;
  font-size: 13px;
  background-color: #FFFFFF; /* Цвет фона должен совпадать с фоном формы */
  padding: 0 5px; /* Для создания эффекта стирающейся границы */

}

.form-textarea:focus + .input-label,
.form-textarea:not(:placeholder-shown) + .input-label {
  top: 20px; /* положение лейбла при фокусе или наличии текста */
  font-size: 1rem;
  left: -1px;
  background-color: #FFFFFF; /* Цвет фона должен совпадать с фоном формы */
  padding: 0 5px; /* Для создания эффекта стирающейся границы */

}


.form-input:focus, .form-textarea {
  outline: none;
  
}

.form-input::placeholder, .form-textarea::placeholder {
  color: transparent; /* делает текст плейсхолдера прозрачным (не видимым) */
}

.css-1ncshvn {
  gap: 0 !important;
}

.balance-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.ref-container {
  display: flex;
  flex-direction: column;
  align-items: space-between; 
  width: 100%;
}

.referral-section {
  background: white;
  width: 70%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.referral-link {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background: #e8e8e8;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
  word-break: break-all;
}

@media (max-width: 1100px) { 
  .referral-section {
    width: 100%;
  }
  .dashboard-container {
    flex-direction: column;
    gap: 1rem;
  }
  .left-dash-container {
    order: 2;
  }
  .bar-container {
    order: 1;
  }
}

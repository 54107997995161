/* Россия */

.site-map-content-container {

    display: flex;

    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    margin: 50px auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    white-space: pre-wrap;
    gap: 1rem;
}

.site-map-content-container a {
    color: #969499;
}

.country-title h2 {
    font-weight: bolder;
    font-size: 1.5rem;
    color: #58555D;
}

.city-site-map-container, .site-map-sections {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 0.5rem;
    margin-top: 0.5rem;
}

.city-site-map-container h4 {
    font-size: 1rem;
    font-weight: 700;
    color: #58555D;
}

.site-map-content-container li  {
    font-size: 0.8rem;
    line-height: 1rem;
    align-self: stretch;
    text-decoration: none;
}

.site-map-wrapper ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 1.5rem;
    gap: 0;
}

.site-map-sections {
    width: 70vw;
}
.site-map-sections li {
    display: inline-block;
    margin-left: 20px;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .site-map-sections {
        width: 100%;
    }
}
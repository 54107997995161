.homepage-wrapper {
    display: flex;
}
.exchange-container {
    flex-direction: column;
    display: flex;
    justify-content: flex-start; /* изменено с center на flex-start */
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    max-width: none; /* Удаляем ограничение по ширине */
    width: 100%;
    margin: 20px auto;
}
.exchange-title {
    align-items: center;
    text-align: center;
    padding: auto;
}

.exchange-title h1 {
    font-size: 2.5rem;
}

.exchange-title span {
    color: rgba(143, 119, 240, 1);
}

.exchange-form {

    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    flex-direction: column; 
}

.exchange-wrapper {
    background-color: #ffffff;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    border-bottom: 2px solid rgba(31, 31, 57, 0.1);
    padding-bottom: 20px !important; 
    flex-direction: row; /* блоки идут слева направо */
    justify-content: space-between; 
    padding: 0 20px;
}

.exchange-section {
    margin-top: 8%;
    flex: none; /* заменяем flex: 1; */
    width: calc(50% - 40px); /* ширина равна половине экрана минус отступы */
    box-sizing: border-box;
    width: calc(50% - 40px);
}

.exchange-container-name{
    text-align: center;
    background-color: #000000; /* Применяем цвет, который вы использовали для ссылок */
    color: #ffffff; 
    border: none; 
    padding: 10px 20px; 
    border-radius: 8px 8px 0 0; 
    transition: background-color 0.3s, transform 0.2s;
    outline: none;
    text-decoration: none; 
    font-size: 16px;
    width: 100%;
    max-width: 1500px;
}

.exchange-container button:last-child {
    margin-right: 0; /* Убираем отступ у последней кнопки */
}

/* Эффект при наведении на кнопку */
.exchange-container button:hover {
    transform: scale(1.05); /* Увеличиваем кнопку на 5% при наведении */
}

/* Эффект при активации кнопки (нажатие) */
.exchange-container button:active {
    background-color: #4074d2;
    transform: scale(0.95); /* Уменьшаем кнопку на 5% при нажатии */
}

/* Эффект для активной формы */
.exchange-container button[data-active="true"] {
    background-color: #4074d2;
    box-shadow: 0 0 10px rgba(64, 116, 210, 0.5); /* Добавляем тень для активной кнопки */
}


.exchange-wrapper,
.section-title-container,
.advantages-section {
    position: relative;
    z-index: 1; /* Устанавливаем z-index выше, чем у SVG, чтобы контент был над изображением */
  }
.currency-svg-icon {
    margin-right: 2%;
    margin-bottom: 2%;
    width: 20px;
    height: 20px;
}
/* Медиа запросы */
@media (max-width: 1024px) {
    .exchange-container button {
        width: 100%; /* кнопки на всю ширину экрана */
        margin: 10px 0; /* вертикальные отступы */
    }
    .currency-svg-icon {
        width: 15px;
        height: 15px;
    }
}



.css-aih845 {
    margin-bottom: 4%;
}

.css-1u9des2-indicatorSeparator {
    display: none;
}

.exchange-section {
    flex: 1; /* Это позволит exchange-section занимать всё доступное пространство, но не более того */
    margin-right: 20px; /* Добавляем немного отступа справа */
}


.neutral-div {
    width: 100%;
  }

.city-wrapper {
    margin-top: 2%;
    display: flex;
    background-color: #ffffff;
    align-items: stretch;
    justify-content: flex-start;
    border-bottom: 2px solid rgba(31, 31, 57, 0.1);
    padding-bottom: 20px !important; 
}

.city-container {
    width: 410%; /* Set the width to 90% of the parent */
    flex-grow: 0; /* Prevent the container from growing beyond its width */
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.city-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 20px;
    text-align: center;
    border-bottom: 2px solid rgba(31, 31, 57, 0.1);
  }


.city-title h2 span {
    color: rgba(143, 119, 240, 1);
  }
  
.city-description, .city-description ul li {
    font-size: 1em;
    line-height: 1.6;
    margin-top: 20px;
    text-align: justify;
    max-width: 800px; /* Adjust as needed for your layout */
    color: #000000;
  }
  
.city-description b {
    font-weight: bold;
  }
  
.city-description p {
    margin-bottom: 15px;
  }
  
.city-description span {
    color: #007bff; /* A different color for emphasis */
    font-weight: bold;
  }
  
.city-description ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
.city-description li {
    text-align: left;
    margin-bottom: 10px;
    font-size: 20px;
  }
  
  /* Pseudo-element for decorative purposes */
.city-description::before {
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    background-color: rgba(143, 119, 240, 1);
    margin: 10px auto;
  }


  @media (max-width: 1024px) {
    .exchange-wrapper {
        flex-direction: column; /* на мобильных устройствах блоки идут сверху вниз */
    }
    .exchange-container {
        padding: 0; /* Убираем внутренние отступы */
        width: 100%; /* Контейнер занимает всю ширину экрана */
    }

    .exchange-wrapper,
    .exchange-section,
    .exchange-form {
        width: 100%; /* Элементы занимают всю ширину контейнера */
        padding: 0; /* Убираем внутренние отступы */
        margin: 0; /* Убираем внешние отступы */
        box-sizing: border-box; /* Учитываем padding и border в общей ширине элементов */
    }

    .svg-sidebar {
          display: none;
        }
    .city-description li {
        font-size: 16px;
    }

}
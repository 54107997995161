.cities-wrapper {
  background-color: rgba(237, 239, 240, 1);
  border-top: 4px solid #9ec8c9;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.cities-container {
  background-color: rgba(237, 239, 240, 1);
  background-image: url('./../../../img/map.webp');
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
  padding: 20px;
}

.row-countries {
  display: flex;
  flex-direction: row;
}

.russian-column {
  margin-top: 10px;
  margin-right: 20px;
  max-width: 300px;
  padding-left: 20px;
}

.cities-title {
  text-align: center;
  margin-bottom: 20px;
}

.cities-container__title {
  display: flex;
  justify-content: center;
}
/* Стили для Masonry */
.masonry-grid {
  display: flex;
  margin-left: -20px; /* Отрицательный левый отступ для компенсации отступа колонок */
  width: auto;
}

.masonry-grid_column {
  padding-left: 20px; /* Отступ для колонок */
  background-clip: padding-box;
}

.masonry-grid_column > .country-block {
  margin-bottom: 20px;
}

.country-block {
  padding: 10px;
  break-inside: avoid; /* Предотвращаем разрыв страны */
}

.с-title {
  font-family: 'Geologica';
  font-weight: 600;
  font-size: 20px;
  color: #2E2A34;
  margin-bottom: 10px;
  text-align: left;
}

.cities-list {
  display: block;
}

.city-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Geologica';
  font-weight: 500;
  font-size: 20px;
  color: #2E2A34;
  margin: 5px 0;
  word-break: break-word; /* Перенос длинного текста на новую строку */
  white-space: normal;
}

.city-item img {
  width: 20px;
  height: 20px;
}

.city-item a {
  text-decoration: none;
  color: #2E2A34;
}

.city-item a:hover {
  color: #8F77F0;
}

/* Адаптивность */
@media (max-width: 768px) {
  .country-title {
    font-size: 18px;
  }


}

@media (max-width: 480px) {
  .country-title {
    font-size: 16px;
  }
  .row-countries {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .russian-column {
    margin-right: 0;
  }
}